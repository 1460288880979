import { useEffect, useState } from "react";
import { useCommunitiesContext } from "../hooks/useCommunitiesContext";
import Slider from "react-slick"; // Import the Slider component

// components
import CommunityDetails from "../components/CommunityDetails";
import { SearchBar } from "../components/SearchBar";

// Import slick carousel styles
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const GroupSearch = () => {
  const { communities, dispatch } = useCommunitiesContext();
  const [results, setResults] = useState([]);

  // Sample communities to display before searching
  const sampleCommunities = communities.slice(0, 5);

  useEffect(() => {
    const fetchCommunities = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/communities`
      );

      const json = await response.json();

      if (response.ok) {
        dispatch({ type: "SET_COMMUNITIES", payload: json });
      }
    };

    fetchCommunities();
  }, [dispatch]);

  // Settings for the slider
  // Settings for the slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000, // Set to a very low value to minimize the transition time
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Attempt to reduce the pause, but react-slick may enforce a minimum delay
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="home">
      <div className="introhome">
        <h1>
          <strong>Find a group</strong>
        </h1>
        <p>Search for a group of people you'd like to connect with</p>
      </div>
      <div className="search-bar-container">
        <SearchBar setResultsInGroupSearchPage={setResults} />
      </div>
      {/* Display the number of search results or sample communities before searching */}
      {results.length > 0 ? (
        <>
          <p className="search-results-count">
            {results.length} result{results.length !== 1 ? "s" : ""} found
          </p>
          {results.map((result) => (
            <CommunityDetails community={result} key={result._id} />
          ))}
        </>
      ) : (
        <>
          <p className="search-results-count">Featured Communities</p>
          <Slider {...sliderSettings}>
            {sampleCommunities.map((community) => (
              <div key={community._id}>
                <CommunityDetails community={community} />
              </div>
            ))}
          </Slider>
        </>
      )}
    </div>
  );
};

export default GroupSearch;